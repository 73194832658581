import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RouteConstants } from "./shared/constant/route-constant";
import { HomeComponent } from "./home/home.component";

const routes: Routes = [
  { path: RouteConstants.home, component: HomeComponent },
  {
    path: RouteConstants.tenentAppDashboard,
    loadComponent: () =>
      import("./tenant-dashboard/tenant-app-dashboard.component").then(
        (m) => m.TenantAppComponent
      ),
  },
  {
    path: RouteConstants.fileUpload,
    loadComponent: () =>
      import("./file-delivery/file-delivery.component").then(
        (m) => m.FileUploadingComponent
      ),
  },

  {
    path: RouteConstants.processingUpdate,
    loadComponent: () =>
      import("./real-time-update/real-time-update.component").then(
        (m) => m.RealTimeUpdateComponent
      ),
  },
  {
    path: RouteConstants.fileSubmissionStatus,
    loadComponent: () =>
      import("./file-submission-history/file-submission-history.component").then(
        (m) => m.FileSubmissionHistoryComponent
      ),
  },
  //   {
  //   path: RouteConstants.fileSubmissionStatus,
  //   loadComponent: () =>
  //     import("./fsubmission/fsubmission.component").then(
  //       (m) => m.FsubmissionComponent
  //     ),
  // },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.fileExplorer,
    loadComponent: () =>
      import("./administration/file-explorer/file-explorer.component").then(
        (m) => m.FileExplorerComponent
      ),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.maintainEnrichment,
    loadComponent: () =>
      import(
        "./administration/enrichment/maintain-enrichment/maintain-enrichment.component"
      ).then((m) => m.MaintainEnrichmentComponent),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.enrichmentUpload,
    loadComponent: () =>
      import(
        "./administration/enrichment/enrichment-upload/enrichment-upload.component"
      ).then((m) => m.EnrichmentUploadComponent),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.enrichmentUploadHistory,
    loadComponent: () =>
      import(
        "./administration/enrichment/enrichment-upload-history/enrichment-upload-history.component"
      ).then((m) => m.EnrichmentUploadHistoryComponent),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.levelOne,
    loadComponent: () =>
      import(
        "./administration/maintain-configuration/level1-configuration/maintain-level1-configuration.component"
      ).then((m) => m.MaintainLevelOneConfigurationComponent),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.levelTwo,
    loadComponent: () =>
      import(
        "./administration/maintain-configuration/level2-configuration/maintain-level2-configuration.component"
      ).then((m) => m.MaintainLevelTwoConfigurationComponent),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.levelThree,
    loadComponent: () =>
      import(
        "./administration/maintain-configuration/level3-configuration/maintain-level3-configuration.component"
      ).then((m) => m.MaintainLevelThreeConfigurationComponent),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.workflowActions,
    loadComponent: () =>
      import(
        "./administration/workflow-actions/workflow-actions.component"
      ).then((m) => m.WorkFlowActionsComponent),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.workflowImportExport,
    loadComponent: () =>
      import(
        "./administration/workflow-import-export/workflow-import-export.component"
      ).then((m) => m.WorkFlowImportExportComponent),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.ClientOffBoardingComponent,
    loadComponent: () =>
      import(
        "./administration/client-off-boarding/client-off-boarding.component"
      ).then((m) => m.ClientOffBoardingComponent),
  },
  {
    path: RouteConstants.CoaAssistantComponent,
    loadComponent: () =>
      import("./coa-ai/coa-assistant/coa-assistant.component").then(
        (m) => m.CoaAssistantComponent
      ),
  },
  {
    path: RouteConstants.CoaDraftHistoryComponent,
    loadComponent: () =>
      import("./coa-ai/coa-draft-history/coa-draft-history.component").then(
        (m) => m.CoaDraftHistoryComponent
      ),
  },
  {
    path: RouteConstants.CoaDraftDetailComponent,
    loadComponent: () =>
      import("./coa-ai/coa-draft-detail/coa-draft-detail.component").then(
        (m) => m.CoaDraftDetailComponent
      ),
  },
  {
    path: RouteConstants.maintainUserDetails,
    loadComponent: () =>
      import(
        "./administration/service-providers/maintain-user-details/maintain-user-details.component"
      ).then((m) => m.MaintainUserDetailsComponent),
  },
  {
    path: RouteConstants.maintainReportTypes,
    loadComponent: () =>
      import(
        "./administration/maintain-report-types/maintain-report-types.component"
      ).then((m) => m.MaintainReportTypesComponent),
  },
  {
    path: RouteConstants.maintainEnrichment,
    loadComponent: () =>
      import(
        "./administration/enrichment/maintain-enrichment/maintain-enrichment.component"
      ).then((m) => m.MaintainEnrichmentComponent),
  },
  {
    path: RouteConstants.maintainEmailTemplates,
    loadComponent: () =>
      import(
        "./administration/maintain-email-templates/maintain-email-templates.component"
      ).then((m) => m.MaintainEmailTemplatesComponent),
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.MissingEnrichmentComponent,
    loadComponent: () =>
      import("./missing-enrichment/missing-enrichment.component").then(
        (m) => m.MissingEnrichmentComponent
      ),
  },
  {
    // canActivate: [DataDeliveryAuthGuardService],
    path: RouteConstants.dataDelivery,
    loadComponent: () =>
      import("./reports/data-delivery/data-delivery.component").then(
        (m) => m.DataDeliveryComponent
      ),
  },
  {
    path:RouteConstants.maintainAllowedValues,
    loadComponent:()=>
      import("./administration/maintain-allowed-values/allowed-values-list/allowed-values-list.component").then((m)=>m.MaintainAllowedValuesComponent)
  },
  {
    // canActivate: [AuthGuardBauService],
    path: RouteConstants.SelfServiceValidationApprovalComponent,
    loadComponent: () =>
      import(
        "./administration/self-service-validation-approval/self-service-validation-approval.component"
      ).then((m) => m.SelfServiceValidationApprovalComponent),
  },

  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.maintainReportingSchedule,
    loadComponent: () =>
      import(
        "./administration/reporting-schedule/maintain-reporting-schedule/maintain-reporting-schedule.component"
      ).then((m) => m.MaintainReportingScheduleComponent),
    // component: MaintainReportingScheduleComponent,
  },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.SelfServiceHub,
    loadComponent: () =>
      import(
        "./administration/self-service-hub/maintain-self-service-hub/self-service-hub.component"
      ).then((m) => m.SelfServiceHubComponent),
  },
  {
    path: RouteConstants.ruleSetup,
    loadComponent: () =>
      import(
        "./administration/file-check-setup/file-check-setup-component"
      ).then((m) => m.FileCheckSetupComponent),
  },
  {
    path: RouteConstants.finDataSource,
    loadComponent: () =>
      import(
        "./administration/maintain-configuration/datasource-configuration/datasource-configuration.component"
      ).then((m) => m.DataSourceConfigurationComponent),
    data: {
      isFds: true
    }
  },
  // {
  //   canActivate: [AuthGuardService],
  //   path: RouteConstants.ruleSetup,
  //   component: FileCheckSetupComponent,
  // },
  // {
  //   canActivate: [AuthGuardService],
  //   path: RouteConstants.finDataSource,
  //   component: DataSourceConfigurationComponent,
  //   data: {
  //     isFds: true,
  //   },
  // },
  // {
  //   canActivate: [AuthGuardService],
  //   path: RouteConstants.comDataSource,
  //   component: DataSourceConfigurationComponent,
  //   data: {
  //     isFds: false,
  //   },
  // },
  {
    // canActivate: [AuthGuardService],
    path: RouteConstants.MissingEnrichmentAdminComponent,
    loadComponent: () =>
      import(
        "./missing-enrichment/missing-enrichment.component"
      ).then((m) => m.MissingEnrichmentComponent),

  },
  {
    //canActivate: [AuthGuardService],
    path: RouteConstants.MissingEnrichmentRmanagerComponent,
    loadComponent: () =>
      import(
        "./missing-enrichment/missing-enrichment.component"
      ).then((m) => m.MissingEnrichmentComponent),

  },
  // {
  //   canActivate: [AuthGuardCoaService],
  //   path: RouteConstants.CoaAssistantComponent,
  //   component: CoaAssistantComponent,
  // },
  /*
  {
    canActivate: [AuthGuardService],
    path: RouteConstants.workflow,
    component: MaintainAlteryxWorkflowComponent,
  },
  */

  // otherwise redirect to home
  { path: "**", redirectTo: "" },
  { path: "", component: HomeComponent },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes_list)],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

