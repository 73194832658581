import { Injectable } from '@angular/core';


@Injectable()
export class OktaWebStorageService {

  public getUrlParameter(name, url) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  private get_okta_access_token_objects() {
    let okta_token_storage_str = sessionStorage.getItem("okta-token-storage");
    if(!okta_token_storage_str){
      return;
    }
    let okta_token_storage = JSON.parse(okta_token_storage_str);
    return okta_token_storage['accessToken'];
  }

  public get_okta_access_token() {
    let okta_token_storage = this.get_okta_access_token_objects();
    if(!okta_token_storage){
      return;
    }
    let accessToken = okta_token_storage['accessToken'];
    return accessToken;
  }

  public get_okta_access_tokene_expiresAt() {
    let okta_token_storage = this.get_okta_access_token_objects();
    if(!okta_token_storage){
      return;
    }
    let expiresAt = okta_token_storage['expiresAt'];
    return expiresAt;
  }
}

