import { Injectable } from "@angular/core";
import { EMPTY } from "rxjs";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";

import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { ErrorDialogService } from "./error-dialog.service";
import { OktaWebStorageService } from "./okta.web.storage.service";

declare var $: any;

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  private authToken;
  public pendingRequests: number;
  public showLoading: boolean;
  private iarClientIP: string = "";
  private iarUserAgent: string = "";
  private okta_token_storage: any = {};
  private accessToken: any = {};

  constructor(
    private router: Router,
    private errorDialogService: ErrorDialogService,
    private oktaWebStorageService: OktaWebStorageService
  ) {
    this.pendingRequests = 0;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.authToken = this.oktaWebStorageService.get_okta_access_token();
    if (!this.authToken) {
      this.errorDialogService.openDialog({ reason: "", status: 401 });
      return EMPTY;
    } else {
      let expiration = this.oktaWebStorageService.get_okta_access_tokene_expiresAt();;
      const expiredTime = Number(expiration)
      if (Date.now() / 1000 >= expiredTime - 60) {
        this.errorDialogService.openDialog({ reason: "", status: 401 });
        return EMPTY;
      }
    }

    this.iarClientIP = sessionStorage.getItem("iar.client.ip.address");
    this.iarUserAgent = sessionStorage.getItem("iar.client.user.agent");
    const reqCloned = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authToken}`,
        IARClientIP: `${this.iarClientIP}`,
        IARUserAgent: `${this.iarUserAgent}`,
      },
    });

    if (reqCloned instanceof HttpRequest) {
      this.pendingRequests++;

      this.turnOnModal();
    }

    this.turnOffModal();

    return next.handle(reqCloned).pipe();
  }

  private turnOnModal() {
    if (!this.showLoading) {
      this.showLoading = true;
      // $("body").spin("modal", "#FFFFFF", "rgba(51, 51, 51, 0.4)");
    }
    this.showLoading = true;
  }

  private turnOffModal() {
    this.pendingRequests--;

    if (this.pendingRequests <= 0) {
      if (this.showLoading) {
        // $("body").spin("modal", "#FFFFFF", "rgba(51, 51, 51, 0.4)");
      }
      this.showLoading = false;
    }
  }
}
