import { BaseService } from "./baseService";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { LogEntry } from "./app-loggers/app-log.service";

@Injectable()
export class UILoggerService extends BaseService {
  constructor(private paramHttpClient: HttpClient) {
    super(paramHttpClient);
  }

  postLogEntry(
    entry: LogEntry
  ): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/UILogger/log`;
    return this.postAndObserve(targetURL, entry);
  }
  
}
