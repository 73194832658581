import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NavLinkComponent } from "../nav-link/nav-link.component";
import { RouteConstants } from '../shared/constant/route-constant';
import { AuthService } from '../shared/auth.service';
import { AuthzUserInfo, UserTypeRoles } from '../models/AuthzUserInfo.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatIconModule } from '@angular/material/icon';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';


export interface NavItem {
  routeName: string;
  routeURL: string;
  routeIcon: string;
  routeEmpty?: boolean;
  childRoutes?: ChildRoute[]
}

export interface ChildRoute {
  routeName: string;
  routeURL: string;
  routeIcon: string;
  routeEmpty?: boolean;
}

@Component({
  selector: 'app-left-nav',
  standalone: true,
  imports: [
    CommonModule,
    NavLinkComponent,MatIconModule
  ],
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss']
})
export class LeftNavComponent implements OnInit {
  navItems: NavItem[] = [];
  ccdirectorRole = "CC Director";
  items: NavItem[] = [
    {
      routeIcon: '<span class="material-icons white mr-5">upload_file</span>',
      routeName: 'File Upload',
      routeURL: '/file-upload'
    },
    {
      routeIcon: '<span class="material-icons white mr-5">history</span>',
      routeName: 'File Submissions',
      routeURL: `${RouteConstants.fileSubmissionStatus}`
    },
    {
      routeIcon:'<span class="material-icons white mr-5"> list_alt</span>',
      routeName:'Self Service Hub',
      routeURL:`${RouteConstants.SelfServiceHub}`,
    },
    {
      routeIcon: '<span class="material-icons white mr-5">manage_accounts</span>',
      routeName: 'Administration',
      routeURL: '',
      childRoutes: [
        {
          routeIcon: '<span class="material-icons white mr-5">text_snippet</span>',
          routeName: 'Maintain Clients & Report-Types',
          routeURL: `${RouteConstants.maintainReportTypes}`,
        },
        {
          routeIcon: ' <span class="material-icons white mr-5"> group </span>',
          routeName: 'Maintain Service Providers',
          routeURL: `${RouteConstants.maintainUserDetails}`,
        },
        {
          routeIcon: '<span class="material-icons white mr-5">view_list </span>',
          routeName: 'Workflows',
          routeURL: `${RouteConstants.workflowActions}`,
        },
        {
          routeIcon: ' <span class="material-icons white mr-5">folder</span>',
          routeName: 'File Explorer',
          routeURL: `${RouteConstants.fileExplorer}`,
        },
      
        {
          routeIcon: '',
          routeName: '',
          routeURL: ``,
          routeEmpty: true
        },
        {
          routeIcon: '<span class="material-icons white mr-5">watch_later</span>',
          routeName: 'Maintain Reporting Schedule',
          routeURL: `${RouteConstants.maintainReportingSchedule}`,
        },
        {
          routeIcon: '<span class="material-icons white mr-5"> email</span>',
          routeName: 'Maintain Email Template',
          routeURL: `${RouteConstants.maintainEmailTemplates}`,
        },
        {
          routeIcon:'<span class="material-icons white mr-5"> list_alt</span>',
          routeName:'Maintain Allowed Values',
          routeURL:`${RouteConstants.maintainAllowedValues}`,
        },
        {
          routeIcon: '<span class="material-icons white mr-5">upload</span>',
          routeName: 'Import/Export',
          routeURL: `${RouteConstants.workflowImportExport}`,
        },
        {
          routeIcon: '',
          routeName: '',
          routeURL: ``,
          routeEmpty: true
        },
        {
          routeIcon: '<span class="material-icons white mr-5">delete_forever</span>',
          routeName: 'Client Data Cleanup',
          routeURL: `${RouteConstants.ClientOffBoardingComponent}`,
        },
       
      ]
    },
    {
      routeIcon: '<span class="material-icons white mr-5">restore_page</span>',
      routeName: 'Enrichment',
      routeURL: '',
      childRoutes: [
        {
          routeIcon: ' <span class="material-icons white mr-5">view_compact_alt</span>',
          routeName: 'Maintain Enrichment',
          routeURL: `${RouteConstants.maintainEnrichment}`,
        },
        {
          routeIcon: '<span class="material-icons white mr-5">upload_file</span>',
          routeName: 'Enrichment Upload',
          routeURL: `${RouteConstants.enrichmentUpload}`,
        },
        {
          routeIcon: '<span class="material-icons white mr-5">list_alt</span>',
          routeName: 'Enrichment Upload History',
          routeURL: `${RouteConstants.enrichmentUploadHistory}`,
        },
      ]
    },
    {
      routeIcon: '<span class="material-icons white mr-5">widgets</span>',
      routeName: 'Configuration',
      routeURL: '',
      childRoutes: [
        {
          routeIcon: '<span class="material-icons white mr-5"> table_chart </span>',
          routeName: 'Level 1 Configuration',
          routeURL: `${RouteConstants.levelOne}`,
        },
        {
          routeIcon: '<span class="material-icons white mr-5">table_view</span>',
          routeName: 'Level 2 Configuration ',
          routeURL: `${RouteConstants.levelTwo}`,
        },
        {
          routeIcon: '<span class="material-icons white mr-5"> table_rows </span>',
          routeName: 'Level 3 Configuration',
          routeURL: `${RouteConstants.levelThree}`,
        },
        {
          routeIcon: '<span class="material-icons white mr-5"> grid_view</span>',
          routeName: 'Fin Data Sync',
          routeURL: `${RouteConstants.finDataSource}`,
        },
      ]
    },
    {
      routeIcon: '<span class="material-icons white mr-5">summarize</span>',
      routeName: 'Reports',
      routeURL: '',
      childRoutes: [
        {
          routeIcon: '<span class="material-icons white mr-5"> bar_chart </span>',
          routeName: 'Data Delivery',
          routeURL: `${RouteConstants.dataDelivery}`,
        },

      ]
    },
    {
      routeIcon: '<span class="material-icons white mr-5">build</span>',
      routeName: 'Tools',
      routeURL: '',
      childRoutes: [
        {
          routeIcon: '<span class="material-icons white mr-5"> batch_prediction</span>',
          routeName: 'CoA AI Assistant',
          routeURL: `${RouteConstants.CoaAssistantComponent}`,
        },
        {
          routeIcon: '<span class="material-icons white mr-5">work_history</span>',
          routeName: 'CoA Draft History',
          routeURL: `${RouteConstants.CoaDraftHistoryComponent}`,
        },

      ]
    },
  ];
  navCollapsed: boolean = false;
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public authService: AuthService, private spinner: NgxSpinnerService,
    private route: Router) {

  }
  public get isUserTypeRoles() {
    return this.authService.CurrentUser
      ? this.authService.CurrentUser.userTypeRoles
      : null;
  }
  ngOnInit() {

  }
   logout() {
    this.spinner.show();
    const signoutUrl = window.location.origin + '/'; // Replace with your desired signout URL
    this.oktaAuth.signOut({ postLogoutRedirectUri: signoutUrl }).then((res: any) => {
      window.sessionStorage.clear();
    });
  }

  canSeeUpload(role: UserTypeRoles) {
    return (role.isAdmin || role.isUser);
  }

  canSeeReports(role: UserTypeRoles) {
    return (role.isAdmin || role.isReportManager);
  }

  canSeeTools(role: UserTypeRoles) {
    return (role.isAdmin || role.isAiUser);
  }

  canSeeSubmissionHistory(role: UserTypeRoles) {
    return (role.isAdmin || role.isUser || role.isReportManager);
  }

  canSeeSelfServiceHub(role: UserTypeRoles) {
    return (role.isAdmin || role.isReportManager);
  }

  enabledNavItems(navItem: NavItem, authUser: AuthzUserInfo) {
    if (!authUser) {
      return false;
    }
    let roles = authUser.userTypeRoles;
    if (!roles) {
      return false;
    }

    if (navItem.routeName == "File Upload" && this.canSeeUpload(roles)) {
      return true;
    }

    if ((navItem.routeName == "File Submissions") && this.canSeeSubmissionHistory(roles)) {
      return true;
    }

    if ((navItem.routeName == "Self Service Hub") && this.canSeeSelfServiceHub(roles)) {
      return true;
    }

    if (navItem.routeName == "Reports" && this.canSeeReports(roles)) {
      return true;
    }

    if (navItem.routeName == "Tools" && this.canSeeTools(roles)) {
      return true;
    }

    if ((navItem.routeName == "Administration"
      || navItem.routeName == "Enrichment"
      || navItem.routeName == "Configuration"
    ) && roles.isAdmin) {
      return true;
    }
    return false;
  }

  toggleNavCollapsed(): void {
    this.navCollapsed = !this.navCollapsed;
    console.log(this.items);
   
  }

  routeLink(routeUrl) {
    if (!routeUrl) {
      return;
    }
    this.route.navigateByUrl(routeUrl);
  }
}
