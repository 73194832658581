import { Observable, of, throwError } from "rxjs";
// import "rxjs/add/observable/of";
import { HttpClient } from "@angular/common/http";
// import "rxjs/add/operator/map";
// import "rxjs/add/operator/catch";
// import "rxjs/add/observable/throw";
import { LogEntry } from "./app-log.service";
import { LogPublisher } from "./app-log-publishers";
import * as _ from "lodash";
import { environment } from "../../../environments/environment";

export class LogLocalStorage extends LogPublisher {
  portal_log_location = "gia.data.agg.platform.portal.logs";
  constructor(private http: HttpClient) {
    // Must call `super()`from derived classes
    super();
    // Set location
  }

  log(entry: LogEntry): Observable<boolean> {
    let is_log_enabled = false;
    // let _location_web_api = '/api/UILogger/Log';
    let _location_web_api = this.location;
    let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    if (currentUser) {
      let settings_log_enabled = _.filter(
        currentUser.appSettings,
        function (e) {
          return e.appSettingName == "IS_LOCAL_STORAGE_LOG_ENABLED";
        }
      );
      let setting_log_enabled = _.first(settings_log_enabled);
      if (setting_log_enabled) {
        if (setting_log_enabled.appSettingValue == "True") {
          is_log_enabled = true;
        }
      }
      let settings_log_web_api_url = _.filter(
        currentUser.appSettings,
        function (e) {
          return e.appSettingName == "WEB_API_LOG_WRITER_URL";
        }
      );
      let setting_log_web_api_url = _.first(settings_log_web_api_url);
      if (setting_log_web_api_url) {
        _location_web_api = !setting_log_web_api_url.appSettingValue
          ? this.location
          : setting_log_web_api_url.appSettingValue;
      }
    }
    if (is_log_enabled) {
      let ret: boolean = false;
      let values: LogEntry[];
      try {
        // Get previous values from local storage
        values =
          JSON.parse(localStorage.getItem(this.portal_log_location)) || [];

        // Add new log entry to array
        values.push(entry);
        // Store array into local storage
        localStorage.setItem(this.portal_log_location, JSON.stringify(values));

        // Set return value
        ret = true;
        let _location = `${environment.apiUrl}${_location_web_api}`;
        return this.http
          .post(_location, entry, { observe: "response" })
          .pipe(this.handleErrors);
      } catch (ex) {
        return of(true);
        // Display error in console
      }
    } else {
      return of(true);
    }
  }
  // Clear all log entries from local storage
  clear(): Observable<boolean> {
    localStorage.removeItem(this.location);
    return of(true);
  }
  private handleErrors(error: any): Observable<any> {
    let errors: string[] = [];
    let msg: string = "";

    msg = "Status: " + error.status;
    msg += " - Status Text: " + error.statusText;
    if (error.json()) {
      msg += " - Exception Message: " + error.json().exceptionMessage;
    }
    errors.push(msg);

    console.error("An error occurred", errors);
    return throwError(errors);
  }
}
